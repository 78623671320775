html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.webviewer {
  flex: 1 1;
  margin: 0px;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App .header {
  width: 100%;
  height: 60px;
  padding: 8px 8px 8px 16px;
  box-sizing: border-box;
  background: #177789;
  font-size: 1.2em;
  line-height: 44px;
  color: white;
}
